@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

@import "./variables.scss";

// FROM ag-grid node_module dist sass
// Keep this as quick reference

// $ag-theme-alpine-default-params: (
//     // COLOURS

//     // main colours (overridden by alpine-dark)
//     alpine-active-color: #2196f3,

//     background-color: #fff,
//     foreground-color: #181d1f,
//     secondary-border-color: #dde2eb,
//     header-background-color: #f8f8f8,
//     odd-row-background-color: #fcfcfc,
//     border-color: #babfc7,
//     control-panel-background-color: #f8f8f8,
//     subheader-background-color: #fff,

//     checkbox-checked-color: ag-derived(alpine-active-color),
//     checkbox-unchecked-color: #999,
//     checkbox-background-color: ag-derived(background-color),

//     // derived colours (shared by alpine-dark)
//     selected-row-background-color: ag-derived(alpine-active-color, $opacity: 0.3),
//     row-hover-color: ag-derived(alpine-active-color, $opacity: 0.1),
//     chip-background-color: ag-derived(foreground-color, $opacity: 0.07),
//     secondary-foreground-color: ag-derived(foreground-color),
//     input-disabled-background-color: ag-derived(background-color, $mix: border-color 20%),
//     disabled-foreground-color: ag-derived(foreground-color, $opacity: 0.5),
//     input-border-color: ag-derived(border-color),
//     header-cell-moving-background-color: ag-derived(background-color),
//     range-selection-border-color: #2196f3,

//     // BORDERS
//     borders: true,
//     borders-side-button: false,
//     border-radius: 3px,
//     selected-tab-underline-transition-speed: 0.3s,

//     header-column-resize-handle: true,
//     header-column-resize-handle-width: 2px,
//     header-column-resize-handle-height: 30%,

//     // SIZING
//     grid-size: 6px,
//     icon-size: 16px,
//     row-height: ag-derived(grid-size, $times: 7),       // if changed, update environment.ts
//     header-height: ag-derived(grid-size, $times: 8),    // if changed, update environment.ts
//     list-item-height: ag-derived(grid-size, $times: 5), // if changed, update environment.ts
//     column-select-indent-size: ag-derived(icon-size),
//     cell-horizontal-padding: ag-derived(grid-size, $times: 3),
//     cell-widget-spacing: ag-derived(grid-size, $times: 2),

//     widget-container-vertical-padding: ag-derived(grid-size, $times: 2),
//     widget-container-horizontal-padding: ag-derived(grid-size, $times: 2),
//     widget-horizontal-spacing: ag-derived(grid-size, $times: 2),
//     widget-vertical-spacing: ag-derived(grid-size, $times: 1.5),

//     toggle-button-height: 18px,
//     toggle-button-width: 28px,

//     input-focus-border-color: ag-derived(alpine-active-color, $opacity: 0.4),
//     input-focus-box-shadow: 0 0 2px 0.1rem ag-derived(input-focus-border-color),

//     // FONTS
//     font-family: (-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif),
//     font-size: 13px,
//     icon-font-family: $ag-theme-alpine-icon-font-family,
//     icons-data: $ag-theme-alpine-icons-data,
//     icons-font-codes: $ag-theme-alpine-icons-font-codes,

//     // MISC
//     selected-tab-underline-color: ag-derived(alpine-active-color),
//     selected-tab-underline-width: 2px,
//     full-width-tabs: true,

//     card-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4),
//     popup-shadow: ag-derived(card-shadow)
// );



.ag-theme-alpine {
  @include ag-theme-alpine(( // use theme parameters where possible
      alpine-active-color: $a1-color-primary,

      // Height of grid rows
      row-height: 32px, //ag-derived(grid-size, $times: 6, $plus: 1),

      // header-height: 48px,

      // Fonts
      font-family: $a1-font-main,
      font-size: 14px,

      foreground-color: #000000,

      border-color: #cecece,

      row-hover-color: #F2F7FC,

      selected-row-background-color: $a1-selected-background,

      range-selection-background-color: #CFE3FC,

      odd-row-background-color: #FAFBFC,


    ));

  .ag-header {
    background: white;
    z-index: 10;
  }

  .ag-header-row {
    font-family: $a1-font-main;
    font-weight: $a1-font-bold;
    color: $color-dark-primary;
  }

  .ag-tooltip {
    margin-top: -65px;
    margin-left: -20px;
    background: #000000;
    font: normal normal normal 12px/20px "IBM Plex Sans";
    color: #FFFFFF;
    border-radius: 4px;
    border: 0;
    padding: 8px 16px;
  }

  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ag-filter-apply-panel-button {
    color: $a1-color-primary;
    line-height: 1;
    border: 1px solid  $a1-color-secondary;
    border-color: #adcce8;
    margin-left: 8px;
    cursor: pointer;

    &:hover:not(:disabled) {
      border-color: $a1-color-primary;
      background: white;
      color: $a1-color-primary;
    }

    &.active,
    &:active {
      background: white;
      border-color: $a1-color-primary;
      color: $a1-color-primary;
    }
  }

  .ag-mini-filter input[class^=ag-][type=text] {
    min-width: 200px;
  }

  .ag-center-cols-container {
    display: block;
    min-width: 100%;
  }

  .ag-row-loading {
    display: none;
  }
}

.ag-header-cell.ag-column-menu-visible {
  color: $a1-color-primary;

  .ag-header-cell.ag-column-menu-visible {

    .a1-grid-header-cell .a1-mask-icon.filter {
      background: $a1-color-primary;

    }
  }
}

.ag-theme-alpine.a1-grid-comps .ag-header {
  border-bottom: none;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none;
  border-top: 0px solid;
  border-radius: 0;
}

.ag-pinned-left-header,
.ag-pinned-left-floating-top {
  box-shadow: 4px 0 5px -1px #0D61A129;
  z-index: 1;

}

.ag-theme-alpine .ag-floating-top {
  border-bottom: none;
}



.ag-pinned-left-cols-container {
  box-shadow: 4px 0 5px -1px #0D61A129;
  z-index: 1;

  .ag-row {
    &.a1-parent-row {
      .ag-cell:last-child {
        border-right-color: #cecece;
      }
    }
  }
}

.ag-theme-alpine {
  .ag-row {
    font-size: 14px;
    font-weight: $a1-font-regular;
    &.a1-exception {
      background: $a1-map-exception-Light;
    }
    &.ag-row-selected {
      background: #C1DBFF;
    }
    &.ag-row-hover {
      background: $a1-selected-option-background-color;
    }
  }
}

.ag-theme-alpine .ag-cell {
  border: 1px solid transparent;
  line-height: 30px;
  padding-left: 12px;
  padding-right: 12px;
}

.ag-theme-alpine .ag-header {
  border-bottom: solid 2px;
  border-bottom-color: #E4EEF7;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: $a1-margin-padding-medium;
  padding-right: 8px;
}

.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell,
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
  border-top: 0;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  content: "";
  position: absolute;
  z-index: 1;
  display: block;
  left: calc(50% - 1px);
  width: 2px;
  height: 50%;
  top: calc(50% - 25%);
  background-color: rgba(186, 191, 199, 0.5);
}


.ag-theme-alpine input[class^=ag-]:not([type]),
.ag-theme-alpine input[class^=ag-][type=text],
.ag-theme-alpine input[class^=ag-][type=number],
.ag-theme-alpine input[class^=ag-][type=tel],
.ag-theme-alpine input[class^=ag-][type=date],
.ag-theme-alpine input[class^=ag-][type=datetime-local],
.ag-theme-alpine textarea[class^=ag-] {
  font-size: inherit;
  line-height: inherit;
  color: inherit;

  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  border-color: #cecece;

  &::placeholder {
    font: Regular 14px/20px Open Sans;
    letter-spacing: 0px;
    color: #CECECE;
  }

  &:focus {
    border-color: $a1-color-primary;
  }
}


.ag-theme-alpine input[class^=ag-]:not([type]):focus,
.ag-theme-alpine input[class^=ag-][type=text]:focus,
.ag-theme-alpine input[class^=ag-][type=number]:focus,
.ag-theme-alpine input[class^=ag-][type=tel]:focus,
.ag-theme-alpine input[class^=ag-][type=date]:focus,
.ag-theme-alpine input[class^=ag-][type=datetime-local]:focus,
.ag-theme-alpine textarea[class^=ag-]:focus {
  outline: none;
  box-shadow: none;
  ;
  // box-shadow: 0 0 2px 0.1rem rgba(255, 20, 147, 0.4);
  border-color: $a1-color-primary;
}

.ag-theme-alpine .ag-checkbox-input-wrapper input,
.ag-theme-alpine .ag-checkbox-input-wrapper input {
  -webkit-appearance: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
}


// .ag-theme-alpine .ag-checkbox-input-wrapper {
//   font-family: "agGridAlpine";
//   font-size: 16px;
//   line-height: 16px;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   width: 20px;
//   height: 20px;
//   background-color: #fff;
//   border-radius: 3px;
//   display: inline-block;
//   vertical-align: middle;
//   flex: none;
// }

.ag-theme-alpine .ag-checkbox-input-wrapper {
  background: none;
  width: 24px;
  height: 24px;

  cursor: pointer;
}


.ag-theme-alpine .ag-checkbox-input-wrapper:focus-within,
.ag-theme-alpine .ag-checkbox-input-wrapper:active {
  box-shadow: none;
  // box-shadow: 0 0 2px 0.1rem rgba(13, 97, 161, 0.4);
}

.ag-theme-alpine .ag-checkbox-input-wrapper::after {
  content: '';
  background: url(/assets/imgs/checkbox_unchecked.svg);
  width: 24px;
  height: 24px;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  content: '';
  background: url(/assets/imgs/checkbox_checked.svg);
  width: 24px;
  height: 24px;

}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::after {
  content: '';
  background: url(/assets/imgs/checkbox-deselect.svg);
  width: 24px;
  height: 24px;
}



.ag-theme-alpine.a1-grid-comps .ag-checkbox-input-wrapper::after {
  content: '';
  background: url(/assets/imgs/comp-add.svg);
  width: 24px;
  height: 24px;
}

.ag-theme-alpine.a1-grid-comps .ag-checkbox-input-wrapper:hover::after {
  content: '';
  background: url(/assets/imgs/comp-add-hover.svg);
  width: 24px;
  height: 24px;
}

.ag-theme-alpine.a1-grid-comps .ag-checkbox-input-wrapper.ag-checked::after {
  content: '';
  background: url(/assets/imgs/comp-minus.svg);
  width: 24px;
  height: 24px;

}

.ag-theme-alpine.a1-grid-comps .ag-selection-checkbox{
  margin-right: 0;
  right:0;
}
.ag-theme-alpine.a1-grid-comps .ag-cell:first-child {
  padding: 0 0 0 8px;
  width: 32px;

}

.ag-theme-alpine.a1-grid-comps.lock .ag-checkbox-input-wrapper {
  pointer-events: none;
  cursor: default;

  &::after {
    background: url(/assets/imgs/comp-add-disabled.svg);
  }
  &:hover::after {
    background: url(/assets/imgs/comp-add-disabled.svg);
  }
  &.ag-checked::after {
    background: url(/assets/imgs/comp-minus-disabled.svg);
  }
}

.ag-theme-alpine .ag-header {
  max-height: 80px !important;
}

.ag-center-aligned-cell {
  text-align: center;
}

.col-address {
  font-weight: $a1-font-semi;
  color: $a1-font-color-dark;
}

.col-center {
  text-align: center;
}

// ********************* LOADER ********************* //

.ag-theme-alpine .ag-overlay-loading-wrapper {
  z-index: 100;
}

.a1-grid-loader {
  width: 48px;
  height: 48px;

  padding-top: 25px;
}

.a1-comp-grid-loader {
  width: 48px;
  height: 48px;

  padding-top: 41px;
}

// ********************* FILTERS POPUP ********************* //

.ag-theme-alpine .ag-menu-header {
  display: none;
}

.ag-tabs.ag-menu.ag-popup-child {
  background-color: white;
  transform: translateY(23px);
}

.ag-theme-alpine .ag-simple-filter-body-wrapper {
  padding: 9px 16px;
}

.ag-theme-alpine .ag-picker-field-wrapper {
  border: 1px solid #E4EEF7;
  border-radius: 4px;
}

.ag-theme-alpine .ag-select .ag-picker-field-wrapper {
  height: 32px;
}

.ag-theme-alpine .ag-picker-field-wrapper:focus {
  border: 1px solid $a1-color-secondary;

  box-shadow: none;
}

.ag-theme-alpine .ag-select .ag-picker-field-display {
  font-family: $a1-font-control;
  font-size: 14px;
  font-weight: $a1-font-medium;
  line-height: 30px;
  color: $a1-color-primary;

  padding: 0px 8px 0 16px;

  margin: 0;
}

.ag-theme-alpine .ag-popup-child:not(.ag-tooltip-custom):not(.ag-tooltip) {
  box-shadow: 0px 2px 3px #0000000D;
  border: 1px solid #E4EEF7;
  border-radius: 4px;
}

.ag-list.ag-select-list.ag-ltr.ag-popup-child {
  transform: translateY(4px);

  box-shadow: 0px 2px 3px #0000000D;
  border: 1px solid #E4EEF7;
  border-radius: 4px;

}

.ag-list-item.ag-select-list-item {
  height: 40px;
  padding: 11px 16px;

  text-align: left;
  font: 400 14px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: $a1-font-color-dark;

  cursor: pointer;
}

.ag-picker-field-icon {
  margin-right: 8px;
}

.ag-theme-alpine .ag-set-filter-select-all {
  font-family: $a1-font-control;
  font-weight: $a1-font-medium;
}

.ag-theme-alpine .ag-list-item, .ag-theme-alpine .ag-virtual-list-item {
  height: 40px;

  .ag-set-filter-item {
    cursor: pointer;
  }

  &:hover {
  background: $color-primary-background;
  }
}

.ag-theme-alpine .ag-simple-filter-body-wrapper >
.ag-filter-condition {
  margin: 7px 0 16px 0;
}


.ag-theme-alpine .ag-picker-field-icon .ag-icon.ag-icon-small-down::before {
  content: '';
  mask: url(/assets/imgs/select_chevron.svg);

  display: block;
  width: 10.828px;
  height: 6.414px;
  margin-right: 6px;

  background: #cecece;
}


// ********************* PINNED ROW ********************* //

.ag-theme-alpine .ag-floating-top {
  min-height: 40px !important;
  height: 40px !important;

 .ag-row {
    min-height: 40px !important;
    height: 40px !important;

  }

  .ag-cell {
    height: 40px !important;

    line-height: 40px !important;

  }
}



.a1-grid-view-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  padding-right: $a1-margin-padding-large;

  height: 57px;
  border-bottom: 1px solid $a1-border-light-color;
}

.a1-grid-view-button {


  // min-width: 168px;
  background-color: transparent;
  border: none;
  outline: none;

  padding: 0;
  .a1-grid-view-button-text-wrapper {
    font-family: $a1-font-control;
    font-size: 16px;
    font-weight: $a1-font-medium;
    line-height: 57px;
    text-align: center;
    color: $a1-font-color-dark;
    padding: 0 34px;

  }

  cursor: pointer;

  height: 100%;

  &:hover {
    background: $a1-background-color;
  }

  position: relative;

  .underline {
    position: absolute;
    bottom: 0;

    height: 3px;
    width: 0;

    background-color: transparent;

    transition: all 300ms ease-in;
  }

  .a1-count-badge {
    display: inline-block;

    height: 28px;
    width: 24px;
    border-radius: 4px;
    margin-left: 8px;
    padding-top: 0.1rem;

    background-color: $color-primary-background;

    font: 500 11px/20px IBM Plex Sans;

    color: $a1-color-primary;
    text-align: center;

    transition: all 300ms ease-in;
  }

  &.active {
    .underline {
      background-color: $a1-color-secondary;
      width: 100%;
    }

    .a1-count-badge {
      color: white;
      background-color: $a1-color-primary;
    }
  }
}

.a1-grid-cell-link {

  span a {
    font: 400 14px/20px Open Sans;
    letter-spacing: 0px;
    color: #000000;
  }
}


.ag-pinned-left-cols-container {
  .a1-grid-tooltip-cell {
    padding: 0;
    line-height: 32px;
  }
  .ag-row.a1-child-row {
    .ag-cell {
      padding-left: 30px;
      &.a1-grid-tooltip-cell{
        padding-left: 0;
        margin-left: 15px;
      }
    }
  }
}

.ag-theme-alpine .ag-body-horizontal-scroll{
  z-index: 2;
}

// $icons: aggregation, arrows, asc, cancel, chart,
// checkbox-checked, checkbox-indeterminate,
// checkbox-unchecked, color-picker, column,
// columns, contracted, copy, cross, cut, data,
// desc, expanded, eye-slash, eye, filter, first,
// grip, group, indeterminate, last, left, loading,
// maximize, menu, minimize, minus, next, none,
// not-allowed, paste, pin, pivot, plus, previous,
// radio-button-off, radio-button-on, right, save,
// small-down, small-left, small-right, small-up,
// tick, tree-closed, tree-indeterminate, tree-open;

// $icon-path: '../resources/icons/';

// @mixin icon-background($path, $icon, $size) {
// background: url('#{$path}#{$icon}.svg') no-repeat;
// background-color: transparent;
// background-position: center;
// background-repeat: no-repeat;
// background-size: $size $size;
// height: $size;
// width: $size;
// }

// // ag-grid.scss defines structural styles and should be imported first
// @import "~ag-grid-community/src/styles/ag-grid.scss";
// @import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss";

// .ag-theme-balham {
// @include ag-theme-balham((
// odd-row-background-color: #f5f5f5,
// row-border-color: transparent,
// row-hover-color: null,
// checkbox-checked-color: #03a9f4,
// range-selection-border-color: #ff00b1,
// range-selection-background-color: #03305633
// ));

// .ag-menu-option-active {
// background-color: #2661ad;
// color: white;
// }

// .ag-icon {
// display: inline-block;
// }

// @each $icon in $icons {
// .ag-icon.ag-icon-#{$icon} {
// @include icon-background($icon-path, $icon, 16px);
// &:before {
//   content: '' !important;
// }
// }
// }

// .ag-icon.ag-icon-row-drag {
// @include icon-background($icon-path, 'grip', 16px);
// &:before {
// content: '' !important;
// }
// }
// }
