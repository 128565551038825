$avenueone-color: #0072EF;
$babyblue: #F2F7FC;
$color-dark-primary: #0A3159;
$color-light-gray: #E9E9F0;

$color-primary: #0072EF;
$color-white: #FFFFFF;
$color-primary-background: #E9F1FB;
$color-disabled: #797979;

$a1-success-color: #00BF64;
$a1-success-color-pale: rgba(0, 192, 139, 0.16);
$a1-alert-color: #FF5D58;
$a1-color-alert-color: #E00700;
$a1-field-lock-unchanged: #525151;

$a1-color-primary: $avenueone-color;
$a1-color-primary-disabled: rgba(13, 97, 161, 0.35);

$a1-color-secondary: #0596FC;

$a1-background-color: $babyblue;
$a1-background-color-light: #FAFBFC;
$a1-background-button-color: #1372EF;
$a1-background-hover-button-color: #005ABC;
$a1-selected-option-background-color: #D5E6FA;
$a1-toggle-selected-disabled: #aac8de;
$a1-download-link-color: #0D61A1;
$a1-offer-ready-reason-color: #FFF4E5;

$a1-border-color: #CECECE;
$a1-border-light-color: #E6E6E6;

$a1-scroll-color: #CECECE;
$a1-scroll-hover-color: #797979;

$a1-font-color-dark: $color-dark-primary;

$a1-selected-background: $color-primary-background;

$a1-dropdown-background: #E4EEF7;

$a1-map-pink-color: #EF3878;
$a1-map-purple-color: #7D37FF;
$a1-map-green-color: #1ba917;

$a1-map-failure-Light: #FCEDED;

$a1-map-exception-Light: #FDF7F7;

$a1-font-main: 'Open Sans', sans-serif;
$a1-font-title: 'IBM Plex Sans', sans-serif;
$a1-font-control: 'IBM Plex Sans', sans-serif;

$a1-header-height: 65px;
$a1-header-height-big: 88px;
$a1-header-height-small: 46px;

$a1-font-regular: 400;
$a1-font-medium: 500;
$a1-font-semi: 600;
$a1-font-bold: 700;

$a1-margin-padding-small: 8px;
$a1-margin-padding-medium: 16px;
$a1-margin-padding-xmedium: 20px;
$a1-margin-padding-large: 24px;
$a1-margin-padding-xlarge: 32px;
$a1-margin-padding-xxlarge: 104px;

$a1-tooltip-background-color: black;
$a1-tooltip-text-color: white;
