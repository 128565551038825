.md-drppicker {
  &.shown {
    transition: none !important;
  }
}

.md-drppicker.a1-md-drppicker  {
  box-shadow: 0px 2px 3px #0000000D;
  border: 1px solid #E4EEF7;
  border-radius: 4px;
  margin-top: 4px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  left: unset !important;
  right: 0 !important;
  width: 648px !important;

  .ranges {
    flex: 0 0 152px;
    background-color: #FAFBFC;
    padding-top: 30px;
    padding-left: 24px;

    ul li {
      &:hover {
        background-color: transparent;

        button {
          color: #0d5abc;
        }
      }

      button {
        color: #797979;
        font-family: $a1-font-title;
        font-size: 14px;
        font-weight: $a1-font-medium;
        padding: 12px;

        &.active {
          background-color: transparent;
          color: #0072EF;
        }
      }
    }
  }

  .buttons {
    flex-grow: 1;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px;
    margin: 0;
    border-top: 1px solid $a1-border-color;

    .buttons_input {
      display: flex;

      .btn {
        align-items: center;
        background-color: #0072EF;
        border-radius: 4px;
        border: 1px solid #ADCCE8;
        box-shadow: none;
        color: #fff;
        display: flex;
        font-family: $a1-font-title;
        font-size: 14px;
        font-weight: $a1-font-medium;
        height: 32px;
        justify-content: center;
        margin-left: $a1-margin-padding-medium;
        padding: 0 17px;
        text-transform: capitalize;

        &.clear {
          background-color: #fff;
          color: #0072EF;
        }

        &[disabled],
        &:disabled {
          &:hover {
            cursor: default;
          }
        }

        svg {
          display: none;
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;

    td.available.prev, th.available.prev,
    td.available.next, th.available.next{
      opacity: 0.2;
      width: 32px;
      height: 32px;
    }
  }

  .calendar {
    padding-top: 80px;
    max-width: 240px;

    td, th {
      min-width: 0;
      min-height: 0;
      height: auto;
      width: auto;
      border: 0;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: 13px;
        padding-top: 3px;
      }

      &.available {
        &:hover {
          background-color: #fff;

          span {
            color: #000 !important;
            background-color: #F0F0F0 !important;
          }
        }
      }
    }

    th {
      &.month {
        color: #0072EF;
        font-family: $a1-font-title;
        font-size: 14px;
        font-weight: $a1-font-medium;
      }
    }

    td {
      transition: none;

      &.start-date.end-date, &.end-date, &.start-date {
        border-radius: 0;
      }

      &.in-range {
        background-color: #fff;
        border: none;

        span {
          background-color: #F2F7FC;
        }
      }

      &.active {
        background-color: #fff;
        span {
          color: #fff !important;
          background-color: #0072EF;
          border-radius: 100% !important;
        }

        &.available {
          &.today {
            span {
              color: #fff;
              background-color: #0072EF;
            }
          }

          &:hover {
            background-color: #fff;

            span {
              background-color: #0072EF;
            }
          }
        }
      }

      &.available {
        &.today {
          span {
            background-color: #F2F7FC;
            color: #0072EF;
            border-radius: 100%;
          }
          &.in-range {
            span {
              color: #000;
              border-radius: 0;
            }
          }

          &:hover {
            span {

            }
          }
        }
      }

      &.off {
        visibility: hidden;
        pointer-events: none;
      }
    }
  }
}

#toolContainer .a1-range-datepicker-inputs {
  color: $color-disabled;
  font-family: $a1-font-title;

  > .a1-range-datepicker-input {
    font-weight: $a1-font-medium;
  }
}
