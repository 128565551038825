@import "./variables.scss";

.a1-scroll-container {
  display: flex;
  flex-direction: column;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
}

.a1-panel-header {
  padding: $a1-margin-padding-large $a1-margin-padding-large $a1-margin-padding-medium $a1-margin-padding-large;
}

.a1-panel-main-title {
  font-family: $a1-font-title;
  font-size: 16px;
  color:$color-dark-primary;
  font-weight: $a1-font-medium;
  summary {
    color: #000000;
    font-size: 14px;
    font-family: $a1-font-main;
    label {
      font-weight: $a1-font-semi;
    }
  }
}

.a1-panel-search {
  padding-top: $a1-margin-padding-medium;
  display: flex;
}

.a1-panel {
  display: flex;
  width: 100%;

  padding-top: 20px;

  &.disabled {
    opacity: 0.48;
  }
}

.a1-panel-title {

  font-family: $a1-font-title;
  font-weight: $a1-font-medium;
  font-size: 14px;
  line-height: 18px;

  color: $a1-font-color-dark;

  margin-bottom: 12px;
}

.a1-vertical-steps {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 12px;

  margin-left: 32px;

  .bullet {
    width: 12px;
    height: 12px;
    border: 2px solid $a1-color-primary;
    border-radius: 8px;
  }

  .points {
    width: 2px;
    flex: 1;

    background-image: url(/assets/imgs/border-dots.svg);
    background-repeat: repeat-y;
  }
}

.a1-panel-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 12px;
  margin: 0 38px 0 32px;
  &.activity-log,
  &.property-details{
    margin:0;
    .a1-button{
      margin-left: 0;
    }
  }
}

.a1-panel-card {
  flex: 1;

  background: #FCFDFF;
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 4px;

  overflow: visible;

  &.undoing {
    background: #FCEDED;
  }

  .a1-completed-icon {
    height: 40px;
    width: 40px;
    margin-right: 20px;
    padding: 12px;

    border-radius: 4px;

    background: #F7F7F7;

    .check-circle {
      background: #CECECE;
    }

    &.completed {

      background: $a1-success-color-pale;

      .check-circle {
        background: #00BF64;
      }
    }
  }

  .a1-step-icon {
    height: 30px;
    width: 40px;
    margin-right: 20px;

    background: url(/assets/imgs/step-arrow.svg) center 3px / 14px no-repeat;
  }

  &.opened {
    max-height: none;
  }

  &.no-bottom-radius {
    border-radius: 4px 4px 0 0;
  }

  &.no-top-radius {
    border-radius: 0 0 4px 4px;
  }

  &.border-top {
    border-top: 1px solid $a1-border-light-color;
  }

  &.closed {
    height: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    border-top: 0;
  }
}

.a1-panel-card-header {
  padding: 20px 20px 4px 20px;
}

.a1-panel-card-step,
.a1-panel-card-content {
  padding: 24px 24px 12px 24px;
}

.a1-panel-card.summary {

  .a1-panel-card-step,
  .a1-panel-card-content {
    padding: 20px;
  }

  .a1-panel-card-step {
    display: flex;
  }

  // When the form is open, we want the bottom border to override the top border of a1-panel-card-step
  .a1-panel-card.form {
    box-shadow: none;
    border-radius: 0;

    .a1-panel-card-content {
      flex-direction: column;
    }

    &.opened {
      max-height: none;
    }

    &.closed {
      height: 0;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      border-top: 0;
    }
  }

  .a1-panel-card-step {
    padding: 15px 20px;
    position: relative;
    transition: all 0.2s;
    overflow: hidden;

    &.closed {
      height: 0;
      opacity: 0;
      padding: 0;
    }
  }

  .separator {
    &::before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 1px;
      width: 87%;
      background: $a1-border-light-color;
    }
  }

  .full-separator {
    &::before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 1px;
      width: 100%;
      background: $a1-border-light-color;
    }
  }
}

.a1-panel-summary {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.a1-panel-border {
  border-top: 1px solid $a1-border-light-color;
  padding-bottom: 15px;
  margin-left: 11%;
}

.a1-panel-title {
  color: 0;

  label {
    font-weight: $a1-font-semi;
  }
}

.a1-panel-summary-timestamp {
  font-size: 13px;
  color: #797979;
}


.a1-panel-main-button {
  font-family: $a1-font-main;
  font-weight: $a1-font-regular;

  margin-left: 0;

  .plus {
    background: white;
    margin-right: 8px;
  }
}

.a1-panel.disabled {

  .a1-panel-main-button {
    color: #0A3159;
    background-color: transparent;

    border: 1px dashed $a1-color-secondary;

    &:disabled {
      opacity: 1;
    }

    &:hover:disabled {
      background-color: transparent;
    }

    .plus {
      background: #0A3159;
      margin-right: 8px;
    }
  }
}

.a1-panel-card-footer {
  height: 62px;
  border-top: 1px solid $a1-border-light-color;
  transition: height 0.2s, opacity 0.2s;
  overflow: hidden;

  padding: 0 24px;

  display: flex;
  justify-content: flex-end;

  .a1-button {
    height: 32px;
  }

  .a1-panel-card-footer-notice {
    font: normal normal normal 13px/20px "Open Sans";
    color: #797979;
  }

  .a1-panel-card-footer-alert {
    font: normal normal normal 13px/20px "Open Sans";
    color: #B84A3D;
  }

  .a1-panel-card-footer-right {
    display: flex;
    margin-left: auto;

    .a1-button:last-of-type:not(.a1-button-stroked){
      min-width: 80px;
    }
  }

  .a1-panel-card-footer-left {
    display: flex;
    margin-right: auto;
  }

  &.a1-button-panel .a1-button {
    margin-left: 8px;
  }

  &.closed {
    height: 0;
    opacity: 0;
  }
  &.opened{
    font-size: 13px;
  }
}

.a1-panel-card-footer-title {
  font-size: 13px;
}

.a1-panel-card.summary .a1-panel-card-footer {
  .a1-button {
    padding: 0 10px;
    margin-left: 8px;
  }
}

@import "src/styles/variables.scss";

#dueDiligenceDaysPanel {
  display: flex;
  align-items: center;
  flex: 1;

  .a1-form-input-label {
    margin: 0;
  }
}

::ng-deep #offerDueDiligenceDaysCount {
  width: 41px;
  margin-left: 12px;

  .a1-input {
    padding: 0 8px;
    text-align: center;
  }
}

::ng-deep #offerDueDiligenceDaysType {
  .a1-dropdown-button {
    border-color: transparent;
  }


  .a1-dropdown-button .a1-button-label {
    font-weight: $a1-font-semi;
    font-size: 12px;
    font-family: $a1-font-main;
    color: #797979;
    text-transform: uppercase;
  }
}

.a1-panel-card-header{
  border-top: 1px solid $a1-border-light-color;
}
