@import 'ngx-toastr/toastr';

@import "./variables.scss";


.toast-container {
  z-index: 100; // Show it above the grids but below the other screens
}

.toast-container .ngx-toastr  {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  background-size: 22px;

  border-radius: 4px;

  height: 48px;
  width: 336px;

  .toast-close-button {
    float: unset;
    position: unset;

    height: 24px;
    width: 24px;

    background: white;
    mask: url(/assets/imgs/close.svg);

    span {
      display: none;
    }
  }
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #999999;
}

.a1-toaster-action {
  margin-right: 8px;
  font-family: $a1-font-control;
  font-weight: 500;

  color: white;

  transition: color 150ms ease-in;
}

.toast-container .ngx-toastr:hover {
  .a1-toaster-action {
    color: #0A3159;
  }
}

.toast-info {
  background-color: #1372EF;
  background-image: url(/assets/imgs/info-white.svg);
}

.a1-toast-message {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.duplicate-sub {
  z-index: 1000;
  top: 0;
  right: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-182px);

  .duplicate-sub-toast {
    margin-top: 24px;
    width: 358px !important;

    .toast-close-button {
      margin-top: 3px !important;
    }
  }
}