@import "./variables.scss";

.a1-input {
  outline: none;

  font-family: $a1-font-control;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  width: 100%;

  border: 1px solid $a1-border-color;
  border-radius: 4px;

  color: $avenueone-color;

  padding: 0 16px;

  &:focus {
    border-color: $a1-color-primary;
  }

  &::placeholder {
    font-family: $a1-font-control;
    font-size: 14px;
    line-height: 20px;

    color: #CECECE;
  }
}

.a1-input-line {
  border: none;
  border-bottom: 1px solid $a1-border-color;
  border-radius: 0;

  &:focus {
    border-color: $a1-color-primary;
  }
}

.a1-form-input {
  position: relative;

  .a1-suffix {
    position: absolute;
    right: 12px;
    top: 0;
    height: 48px;
    display: flex;
    align-items: center;

    cursor: pointer;
  }
}


/* Customize the label (the container) */
.a1-checkbox  {
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.a1-checkbox  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: 0;
}

/* Create a custom checkbox */
.a1-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
}

/* On mouse-over, add a grey background color */
// .a1-checkbox:hover .checkmark {
//   border-radius: 4px;

//   background-color: #dedede;
// }

.a1-input-textarea {
  min-height: 66px;
  padding: 12px;
  resize: none;
}

.app-numeric-input-internal {
  color: $a1-color-primary;
}
