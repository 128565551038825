@page {
  size: auto;
  margin: 0.5cm;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
    margin: 0 auto;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
