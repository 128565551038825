@import "./variables.scss";

.a1-tooltip-directive {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $a1-tooltip-background-color;
  color: $a1-tooltip-text-color;
  padding: 9px 16px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
