@import "./variables.scss";

$button-radius: 4px;

.a1-button,
.a1-button-refresh{
  height: 40px;
  width: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: $button-radius;

  padding: 0 $a1-margin-padding-medium;
  margin-left: 16px;

  border: none;
  outline: none;

  font: 500 14px/20px IBM Plex Sans;
  letter-spacing: 0px;

  cursor: pointer;

  color: $a1-color-primary;
  background: white;

  &:hover:not(:disabled) {
    background: #F2F7FC;
  }

  &.secondary {
  background: $color-primary-background;
  }

  &:disabled {
    opacity: 0.35;
    cursor: default;
  }

  .a1-button-label {
    flex: 1;
    text-align: center;
  }
}

.a1-button-refresh {
  margin-left: 0px;
}

.a1-split-button-button:not(.a1-button-stroked).primary,
.a1-button:not(.a1-button-stroked).primary,
.a1-button-refresh:not(.a1-button-stroked).primary {
  color: white;
  background: $a1-background-button-color;

  &:hover {
    background: $a1-background-hover-button-color;
  }
}

.a1-button-link {
  background: transparent;

  outline: none;
  border: none;

  font-family: $a1-font-main;
  font-size: 14px;
  font-weight: 400;

  color: $avenueone-color;
  cursor: pointer;

  &:hover {
    background: transparent;

    // text-decoration: underline;
  }

  + .a1-button-link{
    padding-left: 20px;
  }

  &.a1-link-alert {
    color: $a1-alert-color;
  }
}

a.a1-button-link{
  text-decoration: none;
  line-height: 41px;
}

.a1-button-stroked {
  color: $a1-color-primary;
  line-height: 1;
  border: 1px solid #e4eef7;

  &:hover:not(:disabled) {
    border-color: $a1-color-secondary;
    background: white;
  }

  &.active {
    border-color: $a1-color-primary;
  }

  .a1-mask-icon {
    margin-right: 8px;
  }
}

.a1-button-stroked.primary {
  color: $a1-color-primary;

  border: 1px solid  $a1-color-secondary;
  border-color: #adcce8;
  &:hover:not(:disabled) {
    border-color: $a1-color-primary;
    background: white;
  }
}

.a1-button-panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .a1-button, .a1-split-button {
    margin-left: 16px;
  }
}

.a1-link {
  font: 400 14px/24px Open Sans;
  letter-spacing: 0px;
  color: $a1-color-primary;
  cursor: pointer;
}

.a1-link-disabled {
  opacity: 0.35;
  cursor: default;
}

.a1-split-button-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: auto;
  padding: 0;

  border: none;
  outline: none;

  font-family: $a1-font-control;
  font-size: 14px;
  font-weight: $a1-font-medium;
  line-height: 20px;

  cursor: pointer;

  &:not(.a1-button-stroked).primary {
    color: white;
    background: $a1-color-primary;

    &:hover {
      background-color: #0C568E;
    }

    &:focus {
      background-color: #0C568E;
    }
  }


  &:disabled {
    opacity: 0.35;
    cursor: default;
  }

  .a1-button-label {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.dyn-p-16 {
      padding: 16px;
    }
  }

  &.a1-split-button-left {
    flex: 1;

    border-top-left-radius: $button-radius;
    border-bottom-left-radius: $button-radius;
  }

  &.a1-split-button-right {
    width: 40px;
    border-left: 1px solid rgba(255, 255,255, 0.16);

    border-top-right-radius: $button-radius;
    border-bottom-right-radius: $button-radius;
  }

}

.a1-button-tool {
  position: relative;

  padding: 0;
  height: 40px;
  width: 40px;

  &:hover {
    background: #F2F7FC;
  }
}

.a1-button-close {
  padding: 0;
  height: 40px;
  width: 40px;

  .a1-mask-icon.close {
    background: #797979;
  }

  &:hover {
    background: white;;

    .a1-mask-icon.close {
      background: $a1-color-primary;
    }
  }
}

.a1-button-clearField {
  z-index: 2;
  position: absolute;
  right: 24px;
  border-radius: 5px;
  height: 39px;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(121, 121, 121, 0.05);
  }

  .a1-mask-icon.close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.a1-button-round {
  height: 32px;
  width: 32px;

  padding: 0;

  background-color: white;
  border: 1px solid #E4EEF7;
  border-radius: 50%;

  cursor: pointer;

  outline: none;

  &:hover {
    background: #F2F7FC;
  }
}

.a1-floating-buttons-container {
  position: fixed;
  bottom: 50px;
  right: 50px;

  .a1-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2),
                0 1px 1px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(245, 245, 245, 0.075);
    text-align: center;
    padding: 0px;
    font-size: 24px;
    cursor: pointer;

    &.primary {
      background: $a1-color-primary;
      color: white;

      .a1-mask-icon {
        background: white;
      }
    }
  }
}
