@import "./variables.scss";


.a1-form {
  .a1-input {
    border-color: #CECECE;
    height: 32px;
    padding: 0 12px;

    color: #000000;
  }

  .a1-input-textarea {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .form-select {
    width: 100%;

    .a1-dropdown-button {
      border-color: $a1-border-color;
      height: 32px;
      width: 100%;
    }

    .a1-dropdown-button .a1-button-label {
      font-weight: $a1-font-regular;
      font-size: 14px;
      font-family: $a1-font-control;
      color: black;
    }
  }

  app-numeric-input input:disabled {
    color: #0A3159;
  }
}

.a1-form-row {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}


.a1-form-input-label {
  font-weight: $a1-font-semi;
  font-size: 12px;
  text-transform: uppercase;

  color: #797979;

  margin-bottom: 4px;

  &.required::after {
    content: ' *';
    color: $avenueone-color;
  }
}

.a1-form-input {
  flex: 1;
  margin-right: 24px;

  &.a1-form-single-input {
    flex: none;
    width: 50%;
  }

  &:last-child {
    margin-right: 0;
  }

}

.a1-form-input.error .a1-input {
  border-color: $a1-alert-color;
  background-color: #FCEDED;
}

.a1-form-input.error .upload-container {
  border-color: $a1-alert-color;
  background-color: #FCEDED;

}

.a1-form-input.error .a1-dropdown-button {
  border-color: $a1-alert-color;
  background-color: #FCEDED;
}
