@import "./variables.scss";

@mixin map-marker-color($color: $a1-color-primary, $is-selected: false) {
  .a1-marker-price {
    @if($is-selected) {
      background-color: $color;
    }

    .a1-marker-value {
      border-color: $color;
      @if($is-selected) {
        background-color: $color;
        color: white;

        i.a1-mask-icon {
          background: white;
        }
      } @else {
        color: $color;
      }

      &::before {
        @if($is-selected) {
          border-top-color: $color;
        }
      }

      &::after {
        border-top-color: $color;
      }
    }
  }

  @if($is-selected) {
    .a1-marker-point {
      background-color: #797979;

      > .a1-mask-icon {
        background-color: $color-white;
        height: 100%;
        width: 100%;
      }
    }
  }
}

// Remove the "bubble arrow"
.gm-style .gm-style-iw-t::after {
  display: none;
}

// this is the GMap InfoWindow popup
.gm-style .gm-style-iw-c {
  border-radius: 4px;
  padding: 0;
  box-shadow: 0px 3px 6px #0000001A;
  z-index: 2;
  overflow: visible;
}

.gm-style .gm-style-iw-c.a1-gmap-comp-preview {

  width: 264px;
  height: 279px;

}

.gm-style .gm-style-iw-t.a1-gmap-comp-preview {

  // Hide the close button
  .gm-ui-hover-effect {
    display: none;
    visibility: hidden;
  }
}

.gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;
}



// gmap sets overflow at scroll and it creates an unwanted padding
// must be visible to fix tootltip overflow
.gm-style .gm-style-iw-d {
  overflow: visible !important;
}


.poi-info-window {
  padding: 16px;
}

.a1-comp-map-marker {
  width: 56px;
  height: 56px;

  cursor: pointer;
  pointer-events: all;

  .a1-marker-price {
    position: relative;

    width: 56px;
    height: 25px;

    border-radius: 4px;
    opacity: 1;

    margin-bottom: 12px;

    .a1-marker-value {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 9px;
      min-width: 56px;
      height: 100%;
      text-align: center;
      font: 600 14px/22px Open Sans;
      box-shadow: 0px 3px 6px #0000001A;

      background-color: white;
      border: 1px solid #E4EEF7;
      border-radius: 4px;

      color: $a1-font-color-dark;

      &::before {
        position: absolute;
        bottom: -8px;
        left: calc(50% - 8px);
        height: 0;
        width: 0;
        z-index: 2;

        content: "\00a0";
        display: block;

        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-top: 8px solid white;
        background-color: transparent;
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        border-top: 9px solid transparent;
        position: absolute;
        z-index: 1;
        bottom: -9px;
        left: calc(50% - 9px);
      }
    }
  }

  .a1-marker-point {
    width: 16px;
    height: 16px;
    margin: 0 auto;
    border-radius: 50%;

    background-color: #797979;
    box-shadow: 1px 2px 3px #00000029;
  }
}

.a1-comp-map-marker.a1-comp-inst-map-marker {

  &.map-pink-inst {
    .a1-marker-price .a1-marker-value {
      color: $a1-map-pink-color;
    }

    .a1-marker-point {
      background-color: $a1-map-pink-color;
    }
  }

  &.map-purple-inst {
    .a1-marker-price .a1-marker-value {
      color: $a1-map-purple-color;
    }

    .a1-marker-point {
      background-color: $a1-map-purple-color;
    }
  }
  &.map-green-inst {
    .a1-marker-price .a1-marker-value {
      color: $a1-map-green-color;
    }
    .a1-marker-point {
      background-color: $a1-map-green-color;
    }
  }

  .a1-marker-price .a1-marker-value {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
}

.a1-map-marker {
  z-index: -1;
}

.a1-map-marker.dot {
  z-index: -2;
}


.a1-map-marker.highlighted {
  z-index: 0;

  .a1-comp-map-marker.a1-comp-inst-map-marker {
    &.map-pink-inst {
      @include map-marker-color($a1-map-pink-color);
    }

    &.map-purple-inst {
      @include map-marker-color($a1-map-purple-color);
    }

    &.map-green-inst {
      @include map-marker-color($a1-map-green-color);
    }
  }

  @include map-marker-color();
}

.a1-map-marker.selected {
  z-index: 0;

  .a1-comp-map-marker.a1-comp-inst-map-marker {
    &.map-pink-inst {
      @include map-marker-color($a1-map-pink-color, true);
    }

    &.map-purple-inst {
      @include map-marker-color($a1-map-purple-color, true);
    }

    &.map-green-inst {
      @include map-marker-color($a1-map-green-color, true);
    }
  }

  @include map-marker-color($a1-color-primary, true);
}



.a1-map-marker.highlighted.dot,
.a1-map-marker.selected.dot {
  z-index: -1;
}

.a1-prop-map-marker {
  z-index: 1;

  .a1-prop-icon {
    width: 28px;
    height: 34px;

    background: url(/assets/imgs/map-pin.svg);
    background-repeat: no-repeat;
    background-position: center;

  }
}
