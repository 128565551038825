@import "./variables.scss";

.a1-loader,
.a1-loader:after {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.a1-button-loader,
.a1-button-loader:after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: default;
  //margin: 19.5px 7px;
  //position: absolute;
}

.a1-loader,
.a1-button-loader{
  // margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid #e4eef7;
  border-right: 4px solid #e4eef7;
  border-bottom: 4px solid #e4eef7;
  border-left: 4px solid $a1-color-primary;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
