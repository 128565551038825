@import "./variables.scss";

.checkbox_checked {
  width: 24px;
  height: 24px;

  background: url(/assets/imgs/checkbox_checked.svg);
}

.checkbox_unchecked {
  width: 24px;
  height: 24px;

  background: url(/assets/imgs/checkbox_unchecked.svg);
}

.checkbox_indeterminate {
  width: 24px;
  height: 24px;

  background: url(/assets/imgs/checkbox_indeterminate.svg);
}

.checkbox_deselect {
  width: 24px;
  height: 24px;

  background: url(/assets/imgs/checkbox-deselect.svg);
}

.arrow-up {
  width: 16px;
  height: 16px;
  background: url(/assets/imgs/arrow-up.svg);
}

.arrow-down {
  width: 16px;
  height: 16px;
  background: url(/assets/imgs/arrow-down.svg);
}

.a1-toast-info {
  background-image: url(/assets/imgs/info.svg);
  background-color: #1372EF;
  color: white;
}

.location {
  width: 24px;
  height: 24px;
  background: url(/assets/imgs/location.svg);
}

.current-price-up {
  width: 10px;
  height: 10px;
  background: url(/assets/imgs/icon-arrow-up.svg);
}

.current-price-down {
  width: 10px;
  height: 10px;
  background: url(/assets/imgs/icon-arrow-down.svg);
}

#pagerPanel {
  .a1-button-tool .a1-mask-icon {
    background: $a1-color-primary;
  }
}

// Masked Icons from here

.a1-mask-icon {
  mask-size: cover;
  display: inline-block;

  &.activitylog {
    width: 22.414px;
    height: 20px;

    mask: url(/assets/imgs/history.svg);
  }

  &.calendar {
    width: 24px;
    height: 24px;
    mask: url(/assets/imgs/calendar.svg);
  }

  &.check {
    mask: url(/assets/imgs/check.svg);
  }

  &.check-circle {
    width: 16px;
    height: 16px;
    background: #00BF64;

    mask: url(/assets/imgs/check-circle.svg);
  }

  &.check-2 {
    width: 16px;
    height: 16px;
    background: #0072EF;

    mask: url(/assets/imgs/Icon-Check.svg);
  }

  &.chevron-down {
    width: 24px;
    height: 24px;
    mask: url(/assets/imgs/chevron-down.svg);
  }

  &.chevron-up {
    width: 24px;
    height: 24px;

    mask: url(/assets/imgs/chevron-up.svg);
  }

  &.chevron-left {
    width: 20px;
    height: 20px;

    mask: url(/assets/imgs/chevron-left.svg);
  }

  &.chevron-right {
    width: 20px;
    height: 20px;

    mask: url(/assets/imgs/chevron-right.svg);
  }

  &.close {
    height: 40px;
    width: 40px;

    background: #797979;
    mask: url(/assets/imgs/close.svg);
  }

  &.close-small {
    width: 10px;
    height: 10px;

    background: #797979;
    mask: url(/assets/imgs/close.svg);
  }
  &.close-thick {
    height: 20px;
    width: 20px;

    background: #797979;
    mask: url(/assets/imgs/close-thick.svg);
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &.close-tag {
    width: 9px;
    height: 9px;

    background: #797979;
    mask: url(/assets/imgs/close-tag.svg);
  }

  &.close-medium {
    height: 20px;
    width: 20px;

    background: #797979;
    mask: url(/assets/imgs/close-medium.svg);
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &.close-thick-small {
    width: 10px;
    height: 10px;

    background: #797979;
    mask: url(/assets/imgs/close-thick.svg);
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &.columns {
    width: 18px;
    height: 18px;

    background: #cecece;
    mask: url(/assets/imgs/columns.svg);

  }

  &.download,
  &.downloadExcel{
    width: 20px;
    height: 20px;
    background: $a1-download-link-color;

    mask: url(/assets/imgs/download.svg);
  }

  &.downloadExcel {
    width: 30px;
    height: 30px;
    mask-size: 130% 130%;
    mask-position: center;
  }

  &.edit {
    width: 20px;
    height: 20px;
    mask: url(/assets/imgs/edit-3.svg);
  }

  &.external {
    width: 20px;
    height: 20px;
    mask: url(/assets/imgs/external-link.svg);
  }

  &.filter {
    width: 20px;
    height: 20px;
    mask: url(/assets/imgs/filter.svg);
  }

  &.filter-full {
    width: 20px;
    height: 20px;
    mask: url(/assets/imgs/filter-full.svg);
  }

  &.image {
    width: 20px;
    height: 20px;
    mask: url(/assets/imgs/image.svg);
  }

  &.info {
    width: 20px;
    height: 20px;
    mask: url(/assets/imgs/info.svg);
  }

  &.lock {
    width: 32px;
    height: 32px;
    mask: url(/assets/imgs/lock.svg);
    background: $a1-border-color;
  }

  &.more-vertical {
    width: 20px;
    height: 20px;

    background: #CECECE;
    mask: url(/assets/imgs/more-vertical.svg);
  }

  &.more-horizontal {
    width: 32px;
    height: 20px;

    background: #CECECE;
    mask: url(/assets/imgs/more-horizontal.svg);
  }

  &.next {
    width: 24px;
    height: 24px;

    background: #CECECE;
    mask: url(/assets/imgs/next.svg);
  }


  &.plus {
    width: 10px;
    height: 10px;

    background: #797979;

    mask: url(/assets/imgs/plus.svg);
  }

  &.feather-plus {
    width: 12px;
    min-width: 12px;
    height: 12px;

    background: black;

    mask: url(/assets/imgs/feather-plus.svg);
  }

  &.photos-grid {
    width: 20px;
    height: 20px;
    background: #797979;
    mask: url(/assets/imgs/photos-grid.svg);
  }

  &.photos-list {
    width: 20px;
    height: 20px;
    background: #797979;
    mask: url(/assets/imgs/photos-list.svg);
  }

  &.photos-placeholder {
    width: 88px;
    height: 88px;
    background: #cecece;
    mask: url(/assets/imgs/photos-placeholder.svg);
  }

  &.minus {
    width: 10px;
    height: 10px;

    background: #797979;

    mask: url(/assets/imgs/minus.svg);
  }

  &.save {
    width: 20px;
    height: 20px;
    mask: url(/assets/imgs/save.svg) no-repeat;
    mask-position: center;
  }

  &.rejection {
    width: 20px;
    height: 20px;
    mask: url(/assets/imgs/circular-plus.svg) no-repeat;
    mask-position: center;
  }
  &.sliders {
    width: 20px;
    height: 20px;

    mask: url(/assets/imgs/sliders.svg);
  }


  &.sort-down {
    width: 14px;
    height: 8px;

    mask: url(/assets/imgs/sort-down.svg);
  }

  &.sort-up {
    width: 14px;
    height: 8px;

    mask: url(/assets/imgs/sort-down.svg);

    transform: rotate(180deg);
  }

  &.trash {
    width: 20px;
    height: 20px;

    background: #cecece;
    mask: url(/assets/imgs/trash-2.svg);
  }

  &.visibility {
    width: 24px;
    height: 24px;

    background: rgba(29, 37, 44, 0.2);

    mask: url(/assets/imgs/visibility.svg);
  }

  &.visibility-off {
    width: 24px;
    height: 24px;

    background: rgba(29, 37, 44, 0.2);

    mask: url(/assets/imgs/visibility_off.svg);
  }

  &.arrow-right-circle {
    width: 18px;
    height: 18px;

    background: #0072EF;

    mask: url(/assets/imgs/arrow-right-circle.svg);
  }

  &.x-circle {
    width: 18px;
    height: 18px;

    background: #FF5D58;

    mask: url(/assets/imgs/x-circle.svg);
  }

  &.alert-circle {
    width: 16px;
    height: 16px;

    background: #d87c00;

    mask: url(/assets/imgs/alert-circle.svg);
  }

  &.alert-exception {
    width: 16px;
    height: 16px;
    background-image: url(/assets/imgs/alert-circle-2.svg);
    background-repeat: no-repeat;
  }

  &.map-pink-inst {
    width: 25px;
    height: 25px;
    flex: 0 0 25px;

    background: $a1-map-pink-color;

    mask: url(/assets/imgs/icon-pink-inst.svg);
  }

  &.map-purple-inst {
    width: 25px;
    height: 25px;
    flex: 0 0 25px;

    background: $a1-map-purple-color;

    mask: url(/assets/imgs/icon-purple-inst.svg);
  }

  &.map-green-inst {
    width: 25px;
    height: 25px;
    flex: 0 0 25px;

    background: $a1-map-green-color;

    mask: url(/assets/imgs/icon-green-inst.svg);
  }

  &.notes {
    width: 40px;
    height: 40px;
    mask: url(/assets/imgs/notes.svg);
  }
  &.tags {
    min-width: 25px;
    max-width: 25px;
    height: 25px;
    margin-left:3px;
    margin-right: 3px;
    background-color: #cecece;
    mask: url(/assets/imgs/tag.svg);
  }

  &.time {
    width: 22px;
    height: 22px;

    background: #cecece;
    mask: url(/assets/imgs/time.svg);
  }

  &.print {
    width: 20px;
    height: 20px;
    background: #fff;
    mask: url(/assets/imgs/print.svg);
  }

  &.refresh {
    background-color: black;
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    mask: url(/assets/imgs/refresh.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  &.external-link {
    background-color: #797979;
    min-width: 13px;
    max-width: 13px;
    height: 13px;
    mask: url(/assets/imgs/external_link.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  &.file-text {
    background-color: #797979;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    mask: url(/assets/imgs/file-text.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  &.check-circle-green {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    background: url(/assets/imgs/check-circle-green.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  &.error-circle {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    background: url(/assets/imgs/error-circle.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
  }
}

i {
  &.active-property {
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    background-image: url(/assets/imgs/checkbox_checked-2.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.blocker {
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    background-image: url(/assets/imgs/blocker.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.alert-approval {
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    background: #d87c00;
    background-image: url(/assets/imgs/alert-approval.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

}

.draggable::before {
  content: "";
  background: #CECECE;
  mask: url(/assets/imgs/more-vertical.svg);
  font-size: 51px;
  display: inline-block;
  height: 80%;
  width: 80%;
  margin-left: -60%;
  margin-top: 10%;
  cursor: move;
}
