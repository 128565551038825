@import "./variables.scss";
@import "./loader.scss";

@import "./ag-grid.scss";
@import "./buttons.scss";
@import "./dragula.scss";
@import "./form.scss";
@import "./gmap.scss";
@import "./icons.scss";
@import "./inputs.scss";;
@import "./panels.scss";
@import "./toastr.scss";
@import "./ngx-daterange.scss";
@import "./print.scss";
@import "./tooltip";

@import 'swiper/swiper.min.css';
@import "@ng-select/ng-select/themes/default.theme.css";

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $a1-font-main;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;

  min-height: 100vh;

  color: black;
}

* {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

h1 {
  font-family: $a1-font-title;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: $a1-color-primary;
  margin: 24px 0;
}

h2 {
  font-family: $a1-font-title;
  font-weight: $a1-font-medium;
  font-size: 20px;
  line-height: 27px;

  margin: 13px 0;

  color: $a1-font-color-dark;
}

h3 {
  font-family: $a1-font-title;
  font-weight: $a1-font-medium;
  font-size: 16px;
  line-height: 20px;

  margin: 10px 0 0 0;

  color: $a1-font-color-dark;
}

h4 {
  font-family: $a1-font-main;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  margin: 6px 0;

  color: #797979;
  text-transform: uppercase;
}

.a1-content {
  padding: 0 24px;
}


/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

.block-with-text {
  @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
}




::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar:horizontal {
  border-top: 1px #CECECE solid;
}

::-webkit-scrollbar-thumb {
  background: $a1-scroll-color padding-box;

  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 7px;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

// just adding :hover as inner to `-thumb` above has no effect
::-webkit-scrollbar-thumb:hover {
  background: $a1-scroll-hover-color padding-box;

  // not specifiying all props again here loses them on hover.
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 7px;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.a1-spacer {
  flex: 1;
}

$a1-color-actionable-background: $color-primary-background;
$a1-color-actionable-color: #0F66DA;
$a1-color-hidden-background: #E6E6E6 ;
$a1-color-hidden-color: #525151 ;
$a1-color-pending-background: #E8F9EF;
$a1-color-pending-color: #167E41;
$a1-color-urgent-background: #FFF4E5;
$a1-color-urgent-color: #AB5805;
$a1-color-alert-background: #FCEDED;
$a1-color-error-color: #FF5D58;

.a1-status {

  display: flex;
  align-items: center;
  height: 100%;

  .a1-status-label {
    height: 16px;
    width: auto;
    border-radius: 16px;

    font-family: $a1-font-control;
    font-weight: $a1-font-medium;
    font-size: 11px;
    line-height: 16px;

    padding: 0 12px;

    &.a1-status-0 {
      color: $a1-color-actionable-color;
      background: $a1-color-actionable-background;
    }
    &.a1-status-1 {
      color: $a1-color-pending-color;
      background: $a1-color-pending-background;
    }
    &.a1-status-2 {
      color: $a1-color-urgent-color;
      background: $a1-color-urgent-background;
    }
    &.a1-status-3 {
      color: $a1-color-hidden-color;
      background: $a1-color-hidden-background;
    }
    &.a1-status-4 {
      color: $a1-color-alert-color;
      background: $a1-color-alert-background;
    }
    &.a1-status-5 {
      color: $a1-color-hidden-color;
      border: 1px solid #CECECE;
      line-height: 15px;
      height: 17px;
    }
    &.a1-status-6 {
      color: $a1-color-error-color;
      border: 1px solid $a1-color-error-color;
      line-height: 15px;
      height: 17px;
    }
  }
}

.a1-col-updated {
  border: 1px solid $a1-color-secondary !important;
  font-weight: 600;
}

.a1-chip {
  display: flex;
  align-items: center;
  line-height: 1;
background: $color-primary-background;

  height: 40px;
  width: auto;

  padding: 0 16px;
  border-radius: 20px;
  margin-right: 16px;

  font-family: $a1-font-control;
  font-size: 14px;
  letter-spacing: 0px;
  color: $a1-color-primary;

  &:last-child {
    margin-right: 0;
  }

  .name {
    font-weight: $a1-font-medium;
    margin-right: 8px;
  }

  .description {
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .a1-chip-button {
    display: flex;
    align-items: center;

    border: none;
    outline: none;
    cursor: pointer;

    padding: 1px;

    background: transparent;
    height: 23px;

    .a1-mask-icon.close {
      height: 23px;
      width: 23px;
    }

    &:hover {
      .a1-mask-icon.close {
        background: $a1-color-primary;

      }
    }
  }
}

.a1-separator {
  height: 16px;
  width: 0px;
  margin: 0 32px;

  border: 1px solid #E6E6E6;
}

.bg-color-a1 {
  background: $a1-background-color;
}

.bg-color-neutral {
  background: white;
}

.flex-spacer {
  flex: 1;
}

.a1-form-input {
    .a1-numeric-input-container{
      input{
        color: #000000;
      }
    }
}

.a1-address {
  width: 216px;
  text-align: left;
  font: normal normal 600 14px/20px Open Sans;
  letter-spacing: 0px;
  color: #0A3159 !important;
}

.a1-drilldown-badge {
  display: inline-block;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 1;

  width: 20px;
  border-radius: 4px;

  background-color: #FC5D58;

  font: normal normal 11px/16px "IBM Plex Sans";

  color: #FFFFFF;
  text-align: center;

  transition: all 300ms ease-in;
  font-weight: 500;
}

.a1-tags-container {
  display: flex;
  justify-content: space-between;
  flex-basis: 22px;
  background-color: #FFF;
  color: #000000;
  align-items: center;

  .a1-tags-container-content {
    display: flex;
    flex-direction: row;
    font-family: $a1-font-control;
    font-size: 14px;
    padding: .1rem $a1-margin-padding-medium 0 24px;
    height: auto;

    >.a1-mask-icon.info {
      background-color: #797979;
      margin: 0 $a1-margin-padding-small;
      mask-size: cover;
      width: 30px;
    }

    .tags-items-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .tags-items {
        border-radius: 4px;
        background-color: #E6E6E6;
        padding: .06rem .3rem;
        margin: 3px 2px 3px 2px;
        font: normal normal 11px/16px IBM Plex Sans;
        font-weight: 600;
        color: #525151;
      }
    }
  }
}

.select-missing-fields-modal-container {
  min-width: 254px !important;
  height: 32px;

  .a1-dropdown-button {
    min-width: 254px !important;
    height: 32px !important;
  }

  .a1-button-label-filled {
    color: $a1-color-primary !important;
  }

  &.fill-selected {
    .a1-dropdown-chevron-icon {
      background: $a1-color-primary !important;
    }
    .a1-dropdown-button {
      background: $color-primary-background;
    }
  }
}

.dataChevronIcon {
  height: 18px;
  width: 18px !important;
  margin-left: 12px;
  bottom: 16px;
  transform: translateY(-50%);
  line-height: 16px !important;
  display: flex;
  justify-content: center;
  top: 50%;
  align-items: center;
  z-index: 1;

  span {
    height: 100%;
    display: inline-flex;
    margin: 0;
    align-items: center;
    .ag-group-value {
      display: none;
    }
  }
}

.dataToolTipIcon {
  height: 18px;
  width: 18px !important;
  bottom: 16px;
  transform: translateY(-50%);
  line-height: 16px !important;
  display: flex;
  justify-content: center;
  top: 50%;
  align-items: center;
  z-index: 1;

  span {
    height: 100%;
    display: inline-flex;
    margin: 0;
    align-items: center;

    .ag-group-value {
      display: none;
    }
  }
}

.a1-missing-data-tooltip-comp {
  padding: unset !important;
  height: 18px;
  width: 18px !important;
  bottom: 16px;
  transform: translateY(-50%);
  line-height: 16px !important;
  display: flex;
  justify-content: center;
  top: 50%;
  align-items: center;

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: unset !important;
    .alert-exception {
      margin-top: 5px;
    }
  }
}

.year-build {
  width: 91px !important;
  height: 32px;

  .a1-dropdown-button {
    width: 91px !important;
    height: 32px !important;
  }

  .a1-button-label-filled {
    color: $a1-color-primary !important;
  }

  &.fill-selected {
    .a1-dropdown-chevron-icon {
      background: $a1-color-primary !important;
    }
    .a1-dropdown-button {
      background: $color-primary-background;
    }
  }
}

.a1-click-to-copy {
  cursor: pointer;
}
